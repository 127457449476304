export const BG_PRIMARY = '#F2F2F2';
export const BG_SECONDARY = '#D6ABA4';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(214 171 164 / 29%)';
// this colors used for green background
export const BG_ALTERNATIVE = '#D6ABA4';
export const TEXT_PRIMARY_LIGHT = '#cf9c2f';
export const TEXT_PRIMARY = '#D6ABA4';
export const TEXT_PRIMARY_DARK = '#000';
export const TEXT_SECONDARY = '#fff';

// Exclusive to template-naomirezky
export const FLOWER_COLOR = '#000';
